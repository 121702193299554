<template>
  <div class="store">
    <div class="panel-title">
      {{ $t("storeManagement") }}
    </div>
    <div class="search-query-container">
      <div class="search-query" style="min-width: 260px;">
        <el-row :gutter="20" style=" display: flex;">
          <el-col :span="12" style="flex: 1; "
            ><el-form @submit.native.prevent>
              <el-form-item :label="$t('storeName')">
                <el-input
                  v-model="searchString"
                  :placeholder="$t('storeName')"
                  clearable
                  @keyup.enter.native="searchStroe()"
                >
                  <!-- <el-button slot="append" icon="el-icon-search" v-on:click="searchUserAuth"> </el-button> -->
                </el-input>
              </el-form-item>
            </el-form>
          </el-col>

          <el-col
            :span="12"
            style="flex: 1; text-align: right;justify-content: flex-end;display: flex;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;"
          >
            <div class="">
              <el-button class="confirm" @click="searchStroe()" :disabled="loading">{{ $t("search") }}</el-button>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <div style="margin-top:20px;text-align: right;">
      <el-button
        class="download cancel"
        icon="el-icon-download"
        :loading="btnDownloadLoading"
        :disabled="btnDownloadLoading"
        v-on:click="downloadData"
      >
        {{ $t("downloadShopList") }}
      </el-button>
    </div>

    <div class="result-background" style="margin-top:20px;">
      <div class="result-table">
        <el-table :data="data" v-loading="loading">
          <el-table-column prop="storeName" :label="$t('storeName')"></el-table-column>
          <el-table-column prop="storeId" :label="$t('storeCode')"></el-table-column>
          <el-table-column width="300" :label="$t('operation')">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-button size="mini" type="text" @click="showPointList(scope)" :disabled="!scope.row.enablePoint"
                  >{{ $t("pointsRuleText") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
                <el-button size="mini" type="text" @click.native="toDetail(scope)">
                  {{ $t("detail") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        background
        layout=" prev, pager, next, sizes, total"
        :total="total"
        :page-size="currentPageSize"
        :page-sizes="[10, 15, 20]"
        :current-page.sync="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
        class="common-pagination"
      >
      </el-pagination>
    </div>

    <side-layer :show-side-layer.sync="detailDrawer" @close="sideLayerClose">
      <div class="setting-nav-title">{{ $t("shopInfo") }}</div>

      <div class="dataTable">
        <div class="dataItem">
          <td class="name">{{ $t("storeName") }}</td>
          <td class="value">{{ detailInfo.storeName }}</td>
        </div>
        <div class="dataItem">
          <td class="name">{{ $t("storeCode") }}</td>
          <td class="value">{{ detailInfo.storeId }}</td>
        </div>
        <div class="dataItem">
          <td class="name">{{ $t("shopCreateDateTime") }}</td>
          <td class="value">{{ dateFormat(detailInfo.createTime) }}</td>
        </div>
      </div>

      <div class="setting-nav-title">{{ $t("storeService") }}</div>
      <el-empty :description="$t('queryNull')" v-if="detailInfo.storeServices == 0"></el-empty>
      <el-tabs v-model="activeName" @tab-click="handleClick" v-else>
        <el-tab-pane
          :label="ele.serviceType === 1 ? $t('offlineService') : $t('onlineService')"
          :name="ele.serviceType === 1 ? 'offlineService' : 'onlineService'"
          v-for="ele in detailInfo.storeServices"
          :key="ele.serviceType + ele.serviceId"
        >
          <div v-if="ele.serviceType === 1" v-loading="detailLoading">
            <div v-if="!detailLoading">
              <div class="dataTable">
                <div class="dataItem">
                  <td class="name">{{ $t("licenseNo") }}</td>
                  <td class="value">{{ serviceInfo.licenseNo }}</td>
                </div>
                <div class="dataItem">
                  <td class="name">{{ $t("qrCode") }}</td>
                  <td class="value"><qrcode :value="serviceInfo.qrCode"></qrcode></td>
                </div>
                <div class="dataItem">
                  <td class="name">{{ $t("accountInfo") }}</td>
                  <td class="value">{{ serviceInfo.bankInfo }}</td>
                </div>
                <div class="dataItem">
                  <td class="name">{{ $t("shopTradeTypes") }}</td>
                  <td class="value">
                    <div class="paylist" v-for="item in serviceInfo.tradeTypes" :key="item">
                      <img :src="getPaymentLogos(item)" :data-desc="item" /> <span>{{ item }}</span>
                    </div>
                  </td>
                </div>
                <div class="dataItem">
                  <td class="name">{{ $t("offlineShopAbbr") }}</td>
                  <td class="value">{{ serviceInfo.shopAbbr }}</td>
                </div>
              </div>

              <!-- <td class="value" v-if="process.env.VUE_APP_ENABLEPOINT">
                      <el-collapse>
                        <div v-for="item in serviceInfo.shopPaymentTypes" :key="item.name">
                          <el-collapse-item :name="item.name" v-if="item.tradeTypeCategory == '1'">
                            <template slot="title">
                              <div class="image-text-container">
                                <img :src="getNormalPayLogoByTradeTypeName(item.name)" /> <span>{{ item.name }}</span>
                              </div>
                            </template>
                            <el-row type="flex" class="row-bg" justify="center" v-if="item.pointCategory == 0">
                              <el-col :span="8"
                                ><el-row>
                                  <i class="el-icon-success" style="color: #2ab875"></i>
                                  <span class="status-text">{{ $t("use") }}</span>
                                </el-row>
                                <el-row>{{ $t("useRate") }}: {{ item.tradeTypeInfo.payLimit }}</el-row>
                              </el-col>
                              <el-col :span="2"></el-col>
                              <el-col :span="8"
                                ><el-row>
                                  <i class="el-icon-success" style="color: #2ab875"></i>
                                  <span class="status-text">{{ $t("give") }}</span>
                                </el-row>
                                <el-row>{{ $t("giveRate") }}: {{ item.tradeTypeInfo.rate }}</el-row>
                              </el-col>
                            </el-row>
                            <el-row type="flex" class="row-bg" justify="center" v-else-if="item.pointCategory == 2">
                              <el-col :span="8">
                                <el-row>
                                  <i class="el-icon-success" style="color: #2ab875"></i>
                                  <span class="status-text">{{ $t("use") }}</span>
                                </el-row>
                                <el-row>{{ $t("useRate") }}: {{ item.tradeTypeInfo.payLimit }}</el-row>
                              </el-col>
                              <el-col :span="2"></el-col>
                              <el-col :span="8"> </el-col>
                            </el-row>
                            <el-row type="flex" class="row-bg" justify="center" v-else>
                              <el-col :span="8"
                                ><el-row>
                                  <i class="el-icon-success" style="color: #2ab875"></i>
                                  <span class="status-text">{{ $t("give") }}</span>
                                </el-row>
                                <el-row>{{ $t("giveRate") }}: {{ item.tradeTypeInfo.rate }}</el-row>
                              </el-col>
                              <el-col :span="2"></el-col>
                              <el-col :span="8"> </el-col>
                            </el-row>
                          </el-collapse-item>
                          <el-collapse-item :name="item.name" v-else-if="item.tradeTypeCategory == '2'">
                            <template slot="title">
                              <div class="image-text-container">
                                <img :src="getNormalPayLogoByTradeTypeName(item.name)" /> <span>{{ item.name }}</span>
                              </div>
                            </template>
                            <el-row type="flex" class="row-bg" justify="space-around">
                              <el-col :span="6">
                                <i class="el-icon-success" style="color: #2ab875"></i>
                                <span class="status-text">{{ $t("cash") }}</span>
                              </el-col>
                              <el-col :span="6">
                                <i class="el-icon-success" style="color: #2ab875"></i>
                                <span class="status-text">{{ $t("wallet") }}</span>
                              </el-col>
                              <el-col :span="6">
                                <i class="el-icon-success" style="color: #2ab875"></i>
                                <span class="status-text">{{ $t("creditCard") }}</span>
                              </el-col>
                            </el-row>
                          </el-collapse-item>
                          <el-collapse-item :name="item.name" v-else-if="item.tradeTypeCategory == '3'">
                            <template slot="title">
                              <div class="image-text-container">
                                <img :src="getNormalPayLogoByTradeTypeName(item.name)" />
                                <span>{{ $t("pointsLimit") }}</span>
                              </div>
                            </template>
                            <el-row type="flex" class="row-bg" justify="center">
                              <el-col :span="8">
                                <el-row
                                  >{{ $t("singleAlertValue") }}:
                                  {{ numFormat(item.tradeTypeInfo.rewardSingleWarningAmount) }}P</el-row
                                >
                                <el-row
                                  >{{ $t("dailyAlertValue") }}:
                                  {{ numFormat(item.tradeTypeInfo.rewardDailyWarningAmount) }}P</el-row
                                >
                              </el-col>
                              <el-col :span="2"> </el-col>
                              <el-col :span="8">
                                <el-row
                                  >{{ $t("singleLimit") }}:
                                  {{ numFormat(item.tradeTypeInfo.rewardSingleLimitAmount) }}P</el-row
                                >
                                <el-row
                                  >{{ $t("dailyLimit") }}:
                                  {{ numFormat(item.tradeTypeInfo.rewardDailyLimitAmount) }}P</el-row
                                >
                              </el-col>
                            </el-row>
                          </el-collapse-item>
                          <div class="paylist" v-else>
                            <img :src="getNormalPayLogoByTradeTypeName(item.name)" /> <span>{{ item.name }}</span>
                          </div>
                        </div>
                      </el-collapse>
                    </td>
                    <td class="value" v-else>
                      <div class="paylist" v-for="item in serviceInfo.tradeTypes" :key="item">
                        <img :src="getNormalPayLogoByTradeTypeName(item)" /> <span>{{ item }}</span>
                      </div>
                    </td> -->

              <shop-device v-if="ele.serviceType === 1" :shopId="serviceInfo.shopId" />
            </div>
            <div class="empty-loading" v-else></div>
          </div>

          <div class="dataTable" v-else v-loading="detailLoading">
            <div class="dataItem">
              <td class="name">{{ $t("signKey") }}</td>
              <td class="value">{{ serviceInfo.licenseNo }}</td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("accountInfo") }}</td>
              <td class="value">{{ serviceInfo.bankInfo }}</td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("shopTradeTypes") }}</td>
              <td class="value">
                <div class="paylist" v-for="item in serviceInfo.tradeTypes" :key="item">
                  <img :src="getPaymentLogos(item)" :data-desc="item" /> <span>{{ item }}</span>
                </div>
              </td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("onlineShopAbbr") }}</td>
              <td class="value">{{ serviceInfo.shopAbbr }}</td>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </side-layer>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import getPaymentLogos from "@/mixins/paymentLogos";

import { storeList, shopInfo, downloadStoresFile } from "api/shop";
import ShopDevice from "./ShopDevice";
import { executeDownload } from "utils/download";
import { dateFormat } from "utils/date";
import { numFormat } from "utils";
import SvgIcon from "../../components/SvgIcon.vue";
export default {
  components: { ShopDevice, SvgIcon },
  mixins: [pagination, getPaymentLogos],
  data() {
    return {
      name: "Store",
      data: [],
      loading: false,
      btnDownloadLoading: false,
      btnLoadingDevice: false,
      detailDrawer: false,
      detailLoading: false,
      isShowPointList: false,
      isShowPointDialog: false,
      detailInfo: {},
      serviceInfo: {},
      searchString: "",
      activeName: "offlineService",
      currentPage: this.$route.params.pageIndex || 1,
      effectivePointListData: [
        {
          id: 1,
          pointType: "E积分",
          ruleId: "T0001",
          activityType: "通用",
          activityName: "通用积分规则",
          activityStartTime: "2022-08-20 14:24:24",
          activityEndTime: "2022-08-31 23:59:59",
          activityDate: "每周五",
          proportion: "100:2"
        }
      ],
      historicalPointListData: [
        {
          id: 1,
          pointType: "E积分",
          ruleId: "T0001",
          activityType: "通用",
          activityName: "通用积分规则",
          activityStartTime: "2022-08-20 14:24:24",
          activityEndTime: "2022-08-31 23:59:59",
          activityDate: "每周五",
          proportion: "100:2",
          state: "有效",
          updateTime: "2022-08-20 14:24:24"
        }
      ],
      addPointsRuleForm: {
        pointType: "",
        activityType: "",
        activityName: "",
        validityPeriod: "",
        activityDateType: "1",
        activityDateMonth: [],
        activityDateWeek: [],
        activityMultiple: "2",
        activityRemark: ""
      },
      addPointsRuleRules: {},
      activityDateMonth: [
        { value: "0", label: "无" },
        { value: "32", label: "每月最后一天" },
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" },
        { value: "24", label: "24" },
        { value: "25", label: "25" },
        { value: "26", label: "26" },
        { value: "27", label: "27" },
        { value: "28", label: "28" },
        { value: "29", label: "29" },
        { value: "30", label: "30" },
        { value: "31", label: "31" }
      ],
      activityDateWeek: [
        { value: "0", label: "无" },
        { value: "1", label: "每周一" },
        { value: "2", label: "每周二" },
        { value: "3", label: "每周三" },
        { value: "4", label: "每周四" },
        { value: "5", label: "每周五" },
        { value: "6", label: "每周六" },
        { value: "7", label: "每周日" }
      ],
      activityMultipleList: [
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
        { value: "6", label: "6" },
        { value: "7", label: "7" },
        { value: "8", label: "8" },
        { value: "9", label: "9" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" }
      ],
      validityPeriodPickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        }
      }
    };
  },
  name: "Store",
  methods: {
    dateFormat,
    numFormat,
    downloadData() {
      this.btnDownloadLoading = true;
      downloadStoresFile({
        storeName: this.searchString
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.btnDownloadLoading = false;
          this.$message.success(this.$t("success"));
        } else {
          this.btnDownloadLoading = false;
        }
      });
    },
    getList(pageIndex, pageSize = this.currentPageSize) {
      this.currentPage = pageIndex || 1;
      this.loading = true;
      let ShopName = this.searchString;
      storeList({
        ShopName,
        pageIndex,
        pageSize
      }).then(response => {
        this.loading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.data = response.result;
          this.total = response.totalCount;
        }
      });
    },
    sideLayerClose() {
      this.activeName = "offlineService";
    },
    toDetail(scope) {
      this.detailInfo = scope.row;
      this.queryShopService();
      this.detailDrawer = true;
    },
    handleClick() {
      this.queryShopService();
    },
    queryShopService() {
      if (this.detailInfo.storeServices.length === 0) {
        return;
      }
      this.detailLoading = true;
      let isOnline = false;
      let shopId = "";
      let offlineService = this.detailInfo.storeServices.find(m => m.serviceType == 1);
      let onlineService = this.detailInfo.storeServices.find(m => m.serviceType == 0);
      if (offlineService && onlineService) {
        if (this.activeName === "offlineService") {
          this.activeName = "offlineService";
          isOnline = false;
          shopId = offlineService.serviceId;
        } else {
          this.activeName = "onlineService";
          isOnline = true;
          shopId = onlineService.serviceId;
        }
      } else if (offlineService) {
        this.activeName = "offlineService";
        isOnline = false;
        shopId = offlineService.serviceId;
      } else {
        this.activeName = "onlineService";
        isOnline = true;
        shopId = onlineService.serviceId;
      }
      shopInfo({ shopId, isOnline }).then(response => {
        this.detailLoading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.serviceInfo = response.result;
        }
      });
    },
    searchStroe(pageIndex = 1, pageSize = this.currentPageSize) {
      this.getList(pageIndex, pageSize);
    },
    showPointList(scope) {
      //this.isShowPointList = true;
      this.$router.push({
        name: "PointsRules",
        params: {
          shopInfo: scope.row,
          pageIndex: this.currentPage
        }
      });
    }
  },
  mounted() {
    if (this.$route.params.pageIndex) {
      this.getList(this.$route.params.pageIndex, this.currentPageSize);
    } else {
      this.getList();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
.empty-loading {
  height: 400px;
}
.el-collapse {
  border: none !important;
}
.el-collapse-item__header {
  border-bottom: none !important;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.paylist {
  height: 36px;
  line-height: 36px;
  display: block;
  margin: 4px 0;
  img {
    float: left;
    width: 60px;
    margin-right: 10px;
  }
}

.shop-search-tools {
  @include flex($wrap: wrap);

  .shop-search-input {
    margin-bottom: 20px;
  }

  .el-button.cancel {
    margin-bottom: 20px;
  }
}

.pointList-title {
  font-size: 18px;
  height: 40px;
  line-height: 40px;
}

.pointList-subTitleBar {
  height: 40px;
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.pointList-subTitleBar h3 {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.pointList-subTitleBar-shopInfo {
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;
  font-size: 14px;
}

.pointList-subTitleBar-shopInfo span {
  margin-right: 20px;
}

.addPointsRule-label {
  font-size: 16px;
  width: 170px;
  display: inline-block;
  text-align: right;
}
</style>
