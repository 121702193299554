<template>
  <div>
    <div class="panel-sub-title">
      <div class="setting-nav-title">{{ $t("shopDeviceTitle") }}</div>

      <div class="device-tools">
        <el-switch v-model="invalidDevice" @change="switchChange"> </el-switch>
        <span class="enable-text">{{ $t("showDisableDevice") }}</span>

        <el-button
          class="download cancel"
          :loading="btnLoadingDevice"
          :disabled="btnLoadingDevice"
          icon="el-icon-download"
          @click="downloadShopDevicesFile"
        >
          {{ $t("downloadShopDevices") }}
        </el-button>
      </div>
    </div>

    <el-table :data="data" v-loading="loading">
      <el-table-column prop="deviceNo" :label="$t('terminalNo')"></el-table-column>
      <el-table-column prop="appVersion" :label="$t('appVer')"></el-table-column>
      <el-table-column prop="deviceModel" :label="$t('device')">
        <template slot-scope="scope">
          {{ scope.row.deviceModel === "" ? $t("unknownDeviceModel") : scope.row.deviceModel }}
        </template>
      </el-table-column>
      <el-table-column prop="lastSignInTime" :label="$t('lastLoginTime')" width="220">
        <template slot-scope="scope">
          <div>
            {{ dateFormat(scope.row.lastSignInTime) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('status')" width="100">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('effective')"
            v-if="scope.row.manageState === 1"
            placement="top-start"
          >
            <svg-icon icon-class="success"></svg-icon>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="$t('uneffective')" v-else placement="top-start">
            <svg-icon icon-class="error"></svg-icon>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
      class="common-pagination"
    >
    </el-pagination>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import { shopDevices, downloadShopDeviceFile } from "api/shop";
import { executeDownload } from "utils/download";
import { dateFormat } from "utils/date";
export default {
  props: {
    shopId: String
  },

  mixins: [pagination],

  data() {
    return {
      dateFormat,
      data: [],
      loading: false,
      btnLoadingDevice: false,
      invalidDevice: false,
      deviceStatusCode: 1
    };
  },

  methods: {
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.currentPage = pageIndex;
      this.loading = true;
      shopDevices({
        pageIndex,
        pageSize,
        shopId: this.shopId,
        status: this.deviceStatusCode
      }).then(response => {
        this.loading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.data = response.result;
          this.total = response.totalCount;
        }
      });
    },

    downloadShopDevicesFile() {
      this.btnLoadingDevice = true;
      downloadShopDeviceFile({ shopId: this.shopId, status: this.deviceStatusCode }).then(response => {
        this.btnLoadingDevice = false;
        if (response.data) {
          executeDownload(response);
          this.$message.success(this.$t("success"));
        }
      });
    },

    switchChange(val) {
      this.deviceStatusCode = val ? 2 : 1;
      this.getList();
    }
  },

  mounted() {
    this.getList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.device-tools {
  @include flex(flex-start);

  .enable-text {
    padding: 0 20px 0 6px;
  }
}
</style>
